.App {
  text-align: center;
  user-select: none;
}

#root {
  user-select: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px grey;
  border-radius: 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 0px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #757474;
}

.navbar-active-indicator {
  border-bottom: 12px solid transparent;
  border-image: url("../public/underline.svg") 30 stretch;
}

.nav-bg {
  background-color: #1c1c1c !important;
}

.nav-container {
  height: 58px;
}

.alert-info {
  height: 70px;
}

.shadow-nav {
  height: 58px !important;
}

.shadow-nav-x2 {
  height: 128px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-14_12 {
  font-size: 12px !important;
}

.fs-16_12 {
  font-size: 12px !important;
}

.fs-18_14 {
  font-size: 14px !important;
}

.fs-16_14 {
  font-size: 14px !important;
}

.fs-16_12 {
  font-size: 12px !important;
}

.fs-20_16 {
  font-size: 16px !important;
}

.fs-24_16 {
  font-size: 16px !important;
}

.fs-32_18 {
  font-size: 18px !important;
}

.fs-40_18 {
  font-size: 18px !important;
}

.mb-80_52 {
  margin-bottom: 52px;
}

.upload-resume-text {
  max-width: 25vw;
  overflow: hidden;
  text-overflow: ellipsis;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.founder-card-divider {
  border-bottom: 1px solid #515151;
}

.alert-dismissible .btn-close {
  display: none;
}

.brand-card {
  height: 200px;
}

.offcanvas-header .btn-close {
  filter: invert();
}

@media (min-width: 768px) {
  .founder-card-divider {
    border-bottom: 0px;
    border-right: 1px solid #515151;
  }
  .alert-dismissible .btn-close {
    /* padding: 0px !important;
    top: 50% !important;
    transform: translateY(-50%) !important; */
    display: none;
  }

  .navbar-active-indicator {
    border-bottom: 2px solid #28527a;
    border-image: none;
  }

  .fs-14 {
    font-size: 14px !important;
  }

  .fs-12 {
    font-size: 12px !important;
  }

  .fs-14_12 {
    font-size: 14px !important;
  }

  .fs-16_12 {
    font-size: 16px !important;
  }

  .fs-18_14 {
    font-size: 18px !important;
  }

  .fs-16_14 {
    font-size: 16px !important;
  }

  .fs-20_16 {
    font-size: 20px !important;
  }

  .fs-24_16 {
    font-size: 24px !important;
  }

  .fs-32_18 {
    font-size: 32px !important;
  }

  .fs-40_18 {
    font-size: 40px !important;
  }
  
  .fs-16_12 {
    font-size: 16px !important;
  }

  .mb-80_52 {
    margin-bottom: 80px;
  }

  .upload-resume-text {
    max-width: 15vw;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media (min-width: 992px) {
  .shadow-nav {
    height: 80px !important;
  }
  .shadow-nav-x2 {
    height: 140px !important;
  }
  .brand-card {
    height: 348px;
  }
  .nav-container {
    height: 80px;
  }
  .alert-info {
    height: 60px;
  }
}
